
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    postNode: Object,
  },
  setup(props) {
    let timeMiddleware = (time: string) => {
      return new Date(parseInt(time) * 1000)
        .toLocaleString()
        .replace(/:\d{1,2}$/, " ");
    };
    return {
      ...toRefs(props),
      timeMiddleware,
    };
  },
});
