
import { defineComponent, ref } from "vue";
import PostNode from "../components/PostNode.vue";

export default defineComponent({
  name: "Home",
  components: {
    PostNode,
  },
  setup(){
    let width = document.body.clientWidth;
    let mobileStatus = ref(true);
    if (width > 1024) {
      mobileStatus.value = false;
    }
    return {
      mobileStatus
    }
  }
});
