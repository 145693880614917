
import axios from "axios";
import { provide, reactive, ref, toRefs } from "vue";
import store from "./store";
export default {
  setup() {
    let width = document.body.clientWidth;
    let bodyStyle = ref("");
    let mobileStatus = ref(false);
    let mobileDrawerStatus = ref(false);
    if (width > 1024) {
      mobileStatus.value = true;
      bodyStyle = ref("body-container");
    } else {
      bodyStyle = ref("body-container-mobile");
    }
    let cookieName = ref(store.getters.getName);
    if (cookieName.value == "" || cookieName.value == null) {
      cookieName.value = "获取饼干";
    }
    let updateCookieName = (name: string) => {
      cookieName.value = name;
    };
    provide("updateCookieName", updateCookieName);
    let res = reactive({
      plateList: [],
    });
    let GetPlate = () => {
      axios.get("plate/get").then((response) => {
        res.plateList = response.data.data;
        store.commit("setPlate", res.plateList);
      });
    };
    GetPlate();
    const activeIndex = ref("1");
    return {
      ...toRefs(res),
      activeIndex,
      cookieName,
      mobileStatus,
      mobileDrawerStatus,
      bodyStyle,
    };
  },
};
