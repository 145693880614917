import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "post-node-contanier" }
const _hoisted_2 = {
  class: "text item",
  style: {"white-space":"pre-wrap"}
}
const _hoisted_3 = {
  key: 0,
  style: {"margin-bottom":"10px"}
}
const _hoisted_4 = { style: {"text-align":"right","font-size":"10px","color":"#63acb5"} }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostNodeHead = _resolveComponent("PostNodeHead")!
  const _component_PostNode = _resolveComponent("PostNode", true)!
  const _component_PostText = _resolveComponent("PostText")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, {
      class: "box-card",
      style: {}
    }, {
      header: _withCtx(() => [
        _renderSlot(_ctx.$slots, "post-head", {}, () => [
          _createVNode(_component_PostNodeHead, { postNode: _ctx.postNode }, null, 8, ["postNode"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postList, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.showStatus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_PostNode, { postNode: item }, null, 8, ["postNode"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          _createVNode(_component_PostText, {
            text: _ctx.postNode.value
          }, null, 8, ["text"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaUrl, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_el_image, {
                style: {"width":"150px","height":"150px"},
                src: item.thumbnailUrl,
                "preview-src-list": [item.url],
                "initial-index": 1,
                "hide-on-click-modal": true,
                fit: "cover"
              }, null, 8, ["src", "preview-src-list"])
            ]))
          }), 128)),
          _renderSlot(_ctx.$slots, "sage-name"),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.deleteStatus)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deletePost(_ctx.postNode.id)))
                }, " 删除我的串 "))
              : _createCommentVNode("", true),
            (_ctx.recoverStatus)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.recoverPost(_ctx.postNode.id)))
                }, " 恢复我的串 "))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_el_popconfirm, {
                title: _ctx.sageInfo.addInfo,
                onConfirm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sageAdd(_ctx.postNode.id)))
              }, {
                reference: _withCtx(() => [
                  _createTextVNode(" 支持SAGE：" + _toDisplayString(_ctx.postNode.sageAddCount), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_popconfirm, {
                title: _ctx.sageInfo.subInfo,
                onConfirm: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sageSub(_ctx.postNode.id)))
              }, {
                reference: _withCtx(() => [
                  _createTextVNode(" 反对SAGE：" + _toDisplayString(_ctx.postNode.sageSubCount), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postNode.lastReplyArr, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "card-reply"
          }, [
            _createTextVNode(_toDisplayString(item.name) + ": " + _toDisplayString(item.value), 1),
            (item.mediaUrl != '[]')
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (查看图片)"))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        _renderSlot(_ctx.$slots, "reply-count")
      ]),
      _: 3
    })
  ]))
}